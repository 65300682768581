import React, {ChangeEvent} from 'react';

interface VideoSourceSelectState {
    videoInputs: MediaDeviceInfo[];
    selectedIndex?: number,
}

interface VideoSourceSelectProps {
    onSelect: (mdi: MediaDeviceInfo) => void;
}

class VideoSourceSelect extends React.Component<VideoSourceSelectProps, VideoSourceSelectState> {
    constructor(props: VideoSourceSelectProps) {
        super(props);
        this.state = {videoInputs: []};
        navigator.mediaDevices.enumerateDevices().then(this.gotDevices.bind(this));
    }

    gotDevices(deviceInfos: MediaDeviceInfo[]) {
        const videoInputs: MediaDeviceInfo[] = [];
        for (let i = 0; i !== deviceInfos.length; ++i) {
            const deviceInfo = deviceInfos[i];
            if (deviceInfo.kind === 'videoinput') {
                videoInputs.push(deviceInfo);
            } else {
                // console.log('Found another kind of device: ', deviceInfo);
            }
        }
        this.setState({videoInputs})
        if (videoInputs.length > 0) {
            const {onSelect} = this.props;
            onSelect(videoInputs[0]);
        }
    }

    handleChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const {videoInputs} = this.state;
        const selectedIndex: number = parseInt(evt.target.value);
        this.setState({selectedIndex});
        this.props.onSelect(videoInputs[selectedIndex]);
    };

    render() {
        const {videoInputs, selectedIndex} = this.state;
        if (!videoInputs) {
            return <div>Loading...</div>
        }
        return <div>
            <label>Video Source</label> <select value={selectedIndex} onChange={this.handleChange}>{videoInputs
            .map((input: MediaDeviceInfo, index: number) => {
                return <option key={input.deviceId} value={index}>{input.label || `Camera source ${index + 1}`}</option>
            })}
        </select>
        </div>
    }
}

export default VideoSourceSelect;

