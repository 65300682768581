import React from 'react';
import './App.css';
import isMobile from './lib/detect-mobile-browser'
import VideoSourceSelect from "./VideoSourceSelect";

declare var Utils: any;
declare var cv: any;

let utils = new Utils('error');

interface AppState {
  ready: boolean;
  videoInput?: MediaDeviceInfo;
  stream?: MediaStream;
}

const FPS = 30;
const width = isMobile() ? 160 : 320;
const height = width * 3 / 4;

class App extends React.Component<any, AppState> {
  videoRef = React.createRef<HTMLVideoElement>();
  streaming = false;
  src: any = null;
  dst: any = null;
  gray: any = null;
  cap: any = null;
  faces: any = null;
  classifier: any = null;
  
  constructor(props: any) {
    super(props);
    utils.loadOpenCv(this.openCvLoaded.bind(this));
    this.state = { ready: false }
  }

  openCvLoaded() {
    let faceCascadeFile = 'haarcascade_frontalface_default.xml';
    utils.createFileFromUrl(faceCascadeFile, faceCascadeFile, () => {
      console.log('File =', faceCascadeFile, 'loaded');
      this.setState({ ready: true });
    });
  }

  processVideo() {
    if (!(this.streaming)) {
      // clean and stop.
      this.src!.delete();
      this.dst!.delete();
      this.gray!.delete();
      this.faces!.delete();
      this.classifier!.delete();

      clearTimeout(1);
      return;
    }
    let begin = Date.now();
    this.cap.read(this.src);
    this.src.copyTo(this.dst);
    cv.cvtColor(this.dst, this.gray, cv.COLOR_RGBA2GRAY, 0);
    // detect faces.
    this.classifier.detectMultiScale(this.gray, this.faces, 1.1, 3, 0);
    // draw faces.
    for (let i = 0; i < this.faces.size(); ++i) {
      let face = this.faces.get(i);
      let point1 = new cv.Point(face.x, face.y);
      let point2 = new cv.Point(face.x + face.width, face.y + face.height);
      cv.rectangle(this.dst, point1, point2, [255, 0, 0, 255]);
    }
    cv.imshow("output", this.dst); // output is the id of another <canvas>;
    // schedule next one.
    let delay = 1000 / FPS - (Date.now() - begin);
    setTimeout(this.processVideo.bind(this), delay);
  }

  start() {
    this.streaming = true;
    const videoElement = this.videoRef.current;

    if (!videoElement) {
      throw Error('Cannot find videoRef')
    }

    const {videoInput} = this.state;

    navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: { exact: videoInput!.deviceId }
      }
    }).then((stream) => {
      videoElement.srcObject = stream
      this.setState({ stream })
    });

    this.src = new cv.Mat(videoElement.height, videoElement.width, cv.CV_8UC4);
    this.dst = new cv.Mat(videoElement.height, videoElement.width, cv.CV_8UC4);
    this.gray = new cv.Mat();
    this.cap = new cv.VideoCapture(videoElement);
    this.faces = new cv.RectVector();
    this.classifier = new cv.CascadeClassifier();

    // load pre-trained classifiers
    this.classifier.load('haarcascade_frontalface_default.xml');
    // schedule first one.
    setTimeout(this.processVideo.bind(this), 0);
  }

  stop() {
    this.streaming = false;
    if (this.state.stream) {
      this.state.stream.getTracks().forEach(function (track) {
        track.stop();
      });
    }
  }

  handleChangeVideoSource(videoInput: MediaDeviceInfo) {
    this.setState({videoInput})
  }

  render() {
    const {ready} = this.state;
    return (
      <div className="App">
        <div id="error"/>
        {ready ? <div>
          <VideoSourceSelect onSelect={this.handleChangeVideoSource.bind(this)}/>
          <div>
            <button id="start" onClick={this.start.bind(this)}>Start</button>
            <button id="stop" onClick={this.stop.bind(this)}>Stop</button>
          </div>
          <video ref={this.videoRef} width={width} height={height} autoPlay={true} style={{ display: 'none' }} />
          <div className="video-container">
            <canvas id="output" />
          </div>
        </div> : <div>Loading...</div>}
      </div>
    );
  }
}

export default App;
